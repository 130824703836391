import React from "react";
import { Footer as FlowbiteFooter } from "flowbite-react";

const Footer = () => {
  return (
    <FlowbiteFooter container>
      <FlowbiteFooter.Copyright href="#" by="ProGamer Offer" year={2024} />
      <FlowbiteFooter.LinkGroup>
        <FlowbiteFooter.Link href="/terms-and-condition">Terms and Condition</FlowbiteFooter.Link>
        <FlowbiteFooter.Link href="/privacy-policy">Privacy Policy</FlowbiteFooter.Link>
        <FlowbiteFooter.Link href="/contact">Contact</FlowbiteFooter.Link>
      </FlowbiteFooter.LinkGroup>
    </FlowbiteFooter>
  );
};

export default Footer;
