import React from "react";
import { Card, Carousel, Button } from "flowbite-react";

const Offers = () => {
  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-4 text-center">Popular Games</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Card 1 */}
        <Card>
          <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
            <Carousel slide={false}>
              <img src="/pic2.jpg" alt="Valorant" />
              <img
                src="https://flowbite.com/docs/images/carousel/carousel-2.svg"
                alt="Valorant"
              />
              <img
                src="https://flowbite.com/docs/images/carousel/carousel-3.svg"
                alt="Valorant"
              />
            </Carousel>
          </div>
          <h1 className="text-3xl font-bold mb-4 text-center">VALORANT</h1>
          <p className="mt-2 text-center">
            Blend your style and experience on a global, competitive stage. You
            have 13 rounds to attack and defend your side using sharp gunplay
            and tactical abilities. And, with one life per-round, you'll need to
            think faster than your opponent if you want to survive. Take on foes
            across Competitive and Unranked modes as well as Deathmatch and
            Spike Rush.
          </p>
          <div className="flex justify-center mt-4">
            <Button
              color="warning"
              className="w-40"
              href="https://playvalorant.com/"
            >
              PLAY NOW
            </Button>
          </div>
        </Card>

        {/* Card 2 */}
        <Card>
          <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
            <Carousel slide={false}>
              <img src="/pic3.png" alt="Game 2" />
              <img
                src="https://flowbite.com/docs/images/carousel/carousel-5.svg"
                alt="Game 2"
              />
              <img
                src="https://flowbite.com/docs/images/carousel/carousel-1.svg"
                alt="Game 2"
              />
            </Carousel>
          </div>
          <h1 className="text-3xl font-bold mb-4 text-center">HALO INFINITE</h1>
          <p className="mt-2 text-center">
            Now is the time for a clash of champions! Relive the battles you
            fought as humanity’s champions in the new Community Reach playlist,
            bringing you back to iconic battles and maps from Halo: Reach. Build
            on your Banished Spartan look with the new Emissary armor in a new
            Operation Pass, and upgrade to the Premium Pass to obtain the
            Reavian Claw weapon model and Falling Flame weapon coating.
          </p>
          <div className="flex justify-center mt-4">
            <Button
              color="warning"
              className="w-40"
              href="https://www.halowaypoint.com/halo-infinite"
            >
              PLAY NOW
            </Button>
          </div>
        </Card>

        {/* Card 3 */}
        <Card>
          <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
            <Carousel slide={false}>
              <img src="pic4.jpg" alt="Game 3" />
              <img
                src="https://flowbite.com/docs/images/carousel/carousel-3.svg"
                alt="Game 3"
              />
              <img
                src="https://flowbite.com/docs/images/carousel/carousel-4.svg"
                alt="Game 3"
              />
            </Carousel>
          </div>
          <h1 className="text-3xl font-bold mb-4 text-center">DESTINY 2</h1>
          <p className="mt-2 text-center">game 3</p>
          <div className="flex justify-center mt-4">
            <Button
              color="warning"
              className="w-40"
              href="https://www.bungie.net/7/en/destiny"
            >
              PLAY NOW
            </Button>
          </div>
        </Card>

        {/* Card 4 */}
        <Card>
          <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
            <Carousel slide={false}>
              <img src="/pic5.jpg" alt="Game 4" />
              <img
                src="https://flowbite.com/docs/images/carousel/carousel-1.svg"
                alt="Game 4"
              />
              <img
                src="https://flowbite.com/docs/images/carousel/carousel-2.svg"
                alt="Game 4"
              />
            </Carousel>
          </div>
          <h1 className="text-3xl font-bold mb-4 text-center">
            TEAM FORTRESS 2
          </h1>
          <p className="mt-2 text-center">game 4</p>
          <div className="flex justify-center mt-4">
            <Button
              color="warning"
              className="w-40"
              href="https://www.teamfortress.com/"
            >
              PLAY NOW
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Offers;
