import React from "react";
import Hero from "../components/Hero";
import { Card } from "flowbite-react";

const PrivacyAndPolicy = () => {
  return (
    <div>
      <Hero />
      <div className="container mx-auto py-12 px-4 lg:px-8"> 
        <h1 className="text-4xl font-bold text-center mb-8">Privacy Policy</h1>
        <p className="text-lg text-center mb-4">Last updated: October 05, 2024</p>

        <Card className="mb-8">
          <p>
            This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
          </p>
          <p>
            We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
          </p>
        </Card>

        <h2 className="text-2xl font-bold mb-4">Interpretation and Definitions</h2>
        <h3 className="text-lg font-semibold mb-2">Interpretation</h3>
        <p className="mb-4">
          The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
        </p>
        
        <h3 className="text-lg font-semibold mb-2">Definitions</h3>
        <p className="mb-4">
          For the purposes of this Privacy Policy:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li><strong>Account:</strong> means a unique account created for You to access our Service or parts of our Service.</li>
          <li><strong>Affiliate:</strong> means an entity that controls, is controlled by or is under common control with a party...</li>
          <li><strong>Company:</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to ProGamer Offer.</li>
          <li><strong>Cookies:</strong> are small files that are placed on Your computer, mobile device or any other device by a website...</li>
          <li><strong>Country:</strong> refers to: Indonesia.</li>
          <li><strong>Device:</strong> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</li>
          <li><strong>Personal Data:</strong> is any information that relates to an identified or identifiable individual.</li>
          <li><strong>Service:</strong> refers to the Website.</li>
          <li><strong>Service Provider:</strong> means any natural or legal person who processes the data on behalf of the Company...</li>
          <li><strong>Third-party Social Media Service:</strong> refers to any website or any social network website through which a User can log in or create an account to use the Service.</li>
          <li><strong>Usage Data:</strong> refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself...</li>
          <li><strong>Website:</strong> refers to ProGamer Offer, accessible from <a href="https://progameroffer.com/" className="text-blue-600">https://progameroffer.com/</a>.</li>
          <li><strong>You:</strong> means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</li>
        </ul>

        <h2 className="text-2xl font-bold mb-4">Collecting and Using Your Personal Data</h2>
        <h3 className="text-lg font-semibold mb-2">Types of Data Collected</h3>
        <h4 className="font-semibold mb-2">Personal Data</h4>
        <p className="mb-4">
          While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Email address</li>
          <li>First name and last name</li>
          <li>Phone number</li>
        </ul>
        <h4 className="font-semibold mb-2">Usage Data</h4>
        <p className="mb-4">
          Usage Data is collected automatically when using the Service. Usage Data may include information such as Your Device’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
        </p>
      </div>
    </div>
  );
};

export default PrivacyAndPolicy;
