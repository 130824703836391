import React from 'react';
import { Navbar } from 'flowbite-react';

const CustomNavbar = () => {
  return (
    <Navbar fluid rounded>
      <Navbar.Brand href="/">
        <img src="/logo.png" className="mr-3 h-6 sm:h-9" alt="ProGamerOffers Logo" />
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-center"> 
        <Navbar.Link href="/" className="text-base"> 
          Home
        </Navbar.Link>
        <Navbar.Link href="/about-us" className="text-base">
          About Us
        </Navbar.Link>
        <Navbar.Link href="/popular-games" className="text-base">
          Popular Games
        </Navbar.Link>
        <Navbar.Link href="/contact" className="text-base">
          Contacts
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default CustomNavbar;
