import React from 'react';
import { Label, TextInput, Textarea, Button } from 'flowbite-react';

const Contact = () => {
  return (
    <div className="p-4 max-w-lg mx-auto">
      <h1 className="text-3xl font-bold text-center mb-6">Contact Us</h1>
      <form>

        <div className="mb-4">
          <Label htmlFor="name" value="Name" />
          <TextInput
            id="name"
            type="text"
            placeholder="Enter your name"
            required
          />
        </div>

        <div className="mb-4">
          <Label htmlFor="email" value="Email" />
          <TextInput
            id="email"
            type="email"
            placeholder="Enter your email"
            required
          />
        </div>

        <div className="mb-4">
          <Label htmlFor="message" value="Message" />
          <Textarea
            id="message"
            placeholder="Type your message"
            required
            rows={4}
          />
        </div>

        <div className="text-center">
          <Button type="submit" color="warning" className="w-40">
            Send Message
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
