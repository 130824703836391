import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CustomNavbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Offers from './pages/Offers';
import PrivacyAndPolicy from './pages/privacyandpolicy';
import TermsAndConditions from './pages/Terms';

const App = () => {
  return (
    <Router>
      <CustomNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/popular-games" element={<Offers/>}/>
        <Route path="/privacy-policy" element={<PrivacyAndPolicy/>}/>
        <Route path="/terms-and-condition" element={<TermsAndConditions/>}/>
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
