import React from "react";
import { Button } from "flowbite-react";

const Hero = () => {
  return (
    <div
      className="flex items-center justify-between p-4 bg-cover bg-center"
      style={{ backgroundImage: "url('/Hero.jpg')" }}
    >
      <div className="flex-1 p-4 rounded-lg">
        <h1 className="text-4xl font-bold text-white">Welcome!</h1>
        <h1 className="text-4xl font-bold text-white">Discover the best free online games</h1>
        <Button className="mt-4" color="warning">
          Learn More
        </Button>
      </div>
      <div className="flex-1">
        <img src="/Hero.jpg" alt="Profile" className="rounded-lg" />{" "}
      </div>
    </div>
  );
};

export default Hero;
