import { Carousel } from "flowbite-react";

const CarouselComponent = () => {
  return (
    <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
      <Carousel>
        <img src="/pic1.jpg" alt="AC" />
        <img src="/pic2.jpg" alt="Valorant" />
        <img src="/pic3.png" alt="Halo" />
        <img src="/pic4.jpg" alt="Destiny" />
        <img src="/pic5.jpg" alt="TM" />
      </Carousel>
    </div>
  );
};

export default CarouselComponent;
