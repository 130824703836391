import React from "react";
import Hero from "../components/Hero";
import CarouselComponent from "../components/Carousel";
import { Card, Button } from "flowbite-react";
import { HiArrowRight } from "react-icons/hi";

const Home = () => {
  return (
    <div>
      <Hero />
      <div className="mt-10 py-12">
        <div className="container mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 text-center">
          <div>
            <h3 className="text-4xl font-bold text-orange-600">20k+</h3>
            <p className="text-lg mt-2">HAPPY CLIENT</p>
          </div>
          <div>
            <h3 className="text-4xl font-bold text-orange-600">100k+</h3>
            <p className="text-lg mt-2">FOLLOWERS</p>
          </div>
          <div>
            <h3 className="text-4xl font-bold text-orange-600">80k+</h3>
            <p className="text-lg mt-2">COUNTRY CLIENT</p>
          </div>
          <div>
            <h3 className="text-4xl font-bold text-orange-600">15</h3>
            <p className="text-lg mt-2">YEARS OF EXPERIENCE</p>
          </div>
        </div>
      </div>

      <Card>
        <div className="flex flex-col md:flex-row items-center justify-between">
          <div className="flex-1 p-4">
            <CarouselComponent />
          </div>
          <div className="flex-1 p-4">
            <h2 className="text-2xl font-bold mb-4">ProGamer Offer</h2>
            <p>
              Games have also become immensely popular, fostering social
              interaction and competition among players. Online gaming
              communities thrive, offering platforms for players to connect,
              cooperate, and compete with others from around the world. Esports,
              or competitive gaming, has gained significant recognition, with
              professional players and teams competing in high-stakes
              tournaments for substantial prize pools. The impact of video games
              extends beyond mere entertainment. They have proven to be a
              powerful medium for storytelling, enabling players.
            </p>
          </div>
        </div>
      </Card>

      <div
        className="relative flex items-center justify-center h-64 bg-cover bg-center text-white mt-6"
        style={{ backgroundImage: `url('/Hero.jpg')` }}
      >
        <div className="text-center">
          <h2 className="text-3xl font-bold mb-4">
            Enjoy our hugely popular games
          </h2>
          <Button
            size="lg"
            className="flex items-center justify-center"
            color="warning"
            href="/popular-games"
          >
            PLAY NOW <HiArrowRight className="ml-2" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Home;
