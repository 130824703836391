import React from "react";
import { Accordion } from "flowbite-react";

const AboutUs = () => {
  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold my-3 text-center">About Us</h1>
      <Accordion>
        <Accordion.Panel>
          <Accordion.Title>Programeoffer</Accordion.Title>
          <Accordion.Content>
            <p className="mb-2 text-gray-500 dark:text-gray-400">
              Games have also become immensely popular, fostering social
              interaction and competition among players. Online gaming
              communities thrive, offering platforms for players to connect,
              cooperate, and compete with others from around the world. Esports,
              or competitive gaming, has gained significant recognition, with
              professional players and teams competing in high-stakes
              tournaments for substantial prize pools. The impact of video games
              extends beyond mere entertainment. They have proven to be a
              powerful medium for storytelling, enabling players.
            </p>
          </Accordion.Content>
        </Accordion.Panel>
      </Accordion>
    </div>
  );
};

export default AboutUs;
